const initState = {
    user: null, // This will be user object outputted in the users service
    isLoading: false,
    error: null, // If a user does not have any credentials on local storage, the reAuthentication will have an error
    isSignedOut: false, // When a user signs out, there will be no reAuthentication, henced need to make state aware of this
};

export const authenticateReducer = (state = initState, action) => {
    switch (action.type) {
    case 'SIGNIN_SUCCESS':
        return {
            ...initState,
            user: action.user,
        };
    case 'SIGNIN_INPROGRESS':
        return {
            ...state,
            isLoading: true
        };
    case 'SIGNIN_ERROR':
        return {
            ...initState,
            error: action.error,
            isLoading: false
        };
    case 'SIGNOUT_SUCCESS':
        return {
            ...initState,
            isSignedOut: true
        };
    default:
        return state;
    }
};

import { combineReducers } from 'redux';

// Custom Reducers
import { generalReducer } from 'reducers/general';
import { authenticateReducer } from 'reducers/auth';

export default function rootReducer(reduxifiedServices) {
    // Combine all reducers here
    return combineReducers({
        users: reduxifiedServices.users.reducer,
        'monitoring-system': reduxifiedServices['monitoring-system'].reducer,
        'newsfeed-system': reduxifiedServices['newsfeed-system'].reducer,
        records: reduxifiedServices.records.reducer,
        organisations: reduxifiedServices.organisations.reducer,
        auth: authenticateReducer,
        general: generalReducer,
    });
}
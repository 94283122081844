/*
This contains all the Redux Sagas (handler of all sideEffects of Dispatch events)
Usage: Notification and Error Detection
*/
// Importing the types of the services
import { reduxifiedServices as services } from 'store/feathersClient';
import { put, takeEvery } from 'redux-saga/effects';

import { addNotificationMessageParams } from 'actions/general';

// Add notification
const handleFulfilled = (serviceType, identifierField, method = 'created') => {
    function* handlerFunction(action) {
        const { payload } = action;
        // Payload is the dispatch action items
        yield put(addNotificationMessageParams({
            message: `${serviceType} (${payload[identifierField]}) has been successfully ${method}`
        }));
    }
    return handlerFunction;
};

const handleRejected = () => {
    function* handlerFunction(action) {
        const { payload } = action;
        // Payload is the dispatch action items
        yield put(addNotificationMessageParams({
            message: payload.message
        }));
    }
    return handlerFunction;
};

// Notification on Failed Login
const handleFailedLogin = () => {
    function* handlerFunction(action) {
        const {message} = action.error;
        if(!(message=='No accessToken found in storage' || message=='jwt expired'))
        {
            yield put(addNotificationMessageParams({
                message: `Failed to sign in: ${action.error.message}`
            }));
        }
    }
    return handlerFunction;
};

// Notification on Failed Login
const handleSuccessLogin = () => {
    function* handlerFunction(action) {
        yield put(addNotificationMessageParams({
            message: `Successful login as ${action.user.email}`
        }));
    }
    return handlerFunction;
};

// Notification on Failed Login
const handlePending = () => {
    function* handlerFunction(action) {
        yield put(addNotificationMessageParams({
            message: 'Processing Request. Please Wait.'
        }));
    }
    return handlerFunction;
};


function* feathersSaga() {

    // USERS services
    yield takeEvery(services.users.types.SERVICES_USERS_CREATE_FULFILLED, handleFulfilled('User', 'email', 'created'));
    yield takeEvery(services.users.types.SERVICES_USERS_PATCH_FULFILLED, handleFulfilled('User', 'email', 'updated'));
    yield takeEvery(services.users.types.SERVICES_USERS_REMOVE_FULFILLED, handleFulfilled('User', 'email', 'removed'));
    yield takeEvery(services.users.types.SERVICES_USERS_FIND_REJECTED, handleRejected());
    yield takeEvery(services.users.types.SERVICES_USERS_CREATE_REJECTED, handleRejected());
    yield takeEvery(services.users.types.SERVICES_USERS_PATCH_REJECTED, handleRejected());
    yield takeEvery(services.users.types.SERVICES_USERS_REMOVE_REJECTED, handleRejected());
    yield takeEvery(services.users.types.SERVICES_USERS_CREATE_PENDING, handlePending());
    yield takeEvery(services.users.types.SERVICES_USERS_PATCH_PENDING, handlePending());
    yield takeEvery(services.users.types.SERVICES_USERS_REMOVE_PENDING, handlePending());

    // Authentication
    yield takeEvery('SIGNIN_ERROR', handleFailedLogin());
    yield takeEvery('SIGNIN_SUCCESS', handleSuccessLogin());

    // monitoring-system services
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_CREATE_FULFILLED'], handleFulfilled('Monitoring Technology', 'name', 'created'));
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_PATCH_FULFILLED'], handleFulfilled('Monitoring Technology', 'name', 'updated'));
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_REMOVE_FULFILLED'], handleFulfilled('Monitoring Technology', 'name', 'removed'));
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_FIND_REJECTED'], handleRejected());
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_CREATE_REJECTED'], handleRejected());
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_PATCH_REJECTED'], handleRejected());
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_REMOVE_REJECTED'], handleRejected());
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_CREATE_PENDING'], handlePending());
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_PATCH_PENDING'], handlePending());
    yield takeEvery(services['monitoring-system'].types['SERVICES_MONITORING-SYSTEM_REMOVE_PENDING'], handlePending());  

    
    // records services
    yield takeEvery(services.records.types.SERVICES_RECORDS_CREATE_FULFILLED, handleFulfilled('Record', '_id', 'created'));
    yield takeEvery(services.records.types.SERVICES_RECORDS_PATCH_FULFILLED, handleFulfilled('Record', '_id', 'updated'));
    yield takeEvery(services.records.types.SERVICES_RECORDS_REMOVE_FULFILLED, handleFulfilled('Record', '_id', 'removed'));
    yield takeEvery(services.records.types.SERVICES_RECORDS_FIND_REJECTED, handleRejected());
    yield takeEvery(services.records.types.SERVICES_RECORDS_CREATE_REJECTED, handleRejected());
    yield takeEvery(services.records.types.SERVICES_RECORDS_PATCH_REJECTED, handleRejected());
    yield takeEvery(services.records.types.SERVICES_RECORDS_REMOVE_REJECTED, handleRejected());
    yield takeEvery(services.records.types.SERVICES_RECORDS_CREATE_PENDING, handlePending());
    yield takeEvery(services.records.types.SERVICES_RECORDS_PATCH_PENDING, handlePending());
    yield takeEvery(services.records.types.SERVICES_RECORDS_REMOVE_PENDING, handlePending());

    // Organisations services
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_CREATE_FULFILLED, handleFulfilled('Organisation', 'name', 'created'));
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_PATCH_FULFILLED, handleFulfilled('Organisation', 'name', 'updated'));
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_REMOVE_FULFILLED, handleFulfilled('Organisation', 'name', 'removed'));
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_FIND_REJECTED, handleRejected());
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_CREATE_REJECTED, handleRejected());
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_PATCH_REJECTED, handleRejected());
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_REMOVE_REJECTED, handleRejected());
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_CREATE_PENDING, handlePending());
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_PATCH_PENDING, handlePending());
    yield takeEvery(services.organisations.types.SERVICES_ORGANISATIONS_REMOVE_PENDING, handlePending());

}

export default feathersSaga;
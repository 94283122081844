/*!

=========================================================
* NextJS Material Dashboard v1.1.0 based on Material Dashboard React v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { Provider } from 'react-redux';
import { SnackbarProvider} from 'notistack';
import withStyles from '@mui/styles/withStyles';
import { StyledEngineProvider, createTheme, ThemeProvider } from '@mui/material/styles';
import PageChange from 'components/MaterialDashboard/PageChange/PageChange.js';
import snackbarStyles from 'assets/jss/custom/snackbarStyles';
import '../assets/scss/nextjs-material-kit-pro.scss';
import '../assets/scss/nextjs-material-dashboard-pro.scss';

import Notification from 'components/Layout/Notification';
import store from 'store/feathersClient';

// This is the Material UI Theming integration with JSS
// These handles the default styling of any component
const theme = createTheme({ });

Router.events.on('routeChangeStart', (url) => {
    console.info(`Loading: ${url}`);
    document.body.classList.add('body-page-transition');
    ReactDOM.render(
        <PageChange path={url} />,
        document.getElementById('page-transition')
    );
});
Router.events.on('routeChangeComplete', () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('page-transition'));
    document.body.classList.remove('body-page-transition');
});
Router.events.on('routeChangeError', () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('page-transition'));
    document.body.classList.remove('body-page-transition');
});

export default class MyApp extends App {
    componentDidMount() {
        let comment = document.createComment(`

=========================================================
* * NextJS Material Dashboard v1.1.0 based on Material Dashboard React v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

`);
        document.insertBefore(comment, document.documentElement);
    }
    static async getInitialProps({ Component, router, ctx }) {
        let pageProps = {};

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        return { pageProps };
    }
    render() {
        const { Component, pageProps } = this.props;

        const Layout = Component.layout || (({ children }) => <>{children}</>);

        const CustomSnackbarProvider = withStyles(snackbarStyles)(SnackbarProvider);

        return (
            <StyledEngineProvider injectFirst>

                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <Head>
                            <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1, shrink-to-fit=no"
                            />
                            <title>Tailings Monitoring</title>
                        </Head>
                        <CustomSnackbarProvider maxSnack={5}
                            preventDuplicate={true}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        >
                            <Notification />
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        </CustomSnackbarProvider>
                    </Provider>
                </ThemeProvider>

            </StyledEngineProvider>

        );
    }
}
